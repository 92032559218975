import React, {
	useCallback,
	useEffect,
	useMemo,
	useRef,
	useState,
} from "react";
import "ag-grid-community/styles/ag-grid.css";
import "ag-grid-community/styles/ag-theme-alpine.css";
import { useNavigate, useLocation } from "react-router-dom";
import { AgGridReact } from "ag-grid-react";
import Card from "../UI/Card";
import { TextField, Grid, Divider, Box } from "@mui/material";
import SearchIcon from "@mui/icons-material/Search";
import ClearIcon from '@mui/icons-material/Clear';
import IconTextField from "../UI/IconTextField";
import { Avatar } from "primereact/avatar";
import { INDIVIDUAL_COLUMNS } from "../../headers/ColumnHeader";
import { dbQuery } from "../../api/db";
import ButtonIcon from "../UI/ButtonIcon";
import Button from "../UI/Button";
import { InputText } from "primereact/inputtext";
import { IconField } from "primereact/iconfield";
import { InputIcon } from "primereact/inputicon";
import useZustandToken from "../useZustandToken";
import { debug } from "../../utils/ConsoleHelper";
import { debounce } from 'lodash'
import { RiUserSearchFill } from "react-icons/ri";
import { RiUserSearchLine } from "react-icons/ri";
import { GrClearOption } from "react-icons/gr";
import { FaGripLinesVertical } from "react-icons/fa";

import "./Individuals.css";

const People = (props) => {
	const gridRef = useRef();
	const [gridApi, setGridApi] = useState(null);
	let col_headers = INDIVIDUAL_COLUMNS;
	const [rawData, setRawData] = useState();
	const navigate = useNavigate();
	const [columnDefs] = useState(col_headers);
	const { update, search, setSearch } = useZustandToken()
	const [curLN, setCurLN] = useState("NGO")
	//const [search, setSearch] = useState()

	useEffect(() => {
		getData(`SELECT * from ${curLN} ORDER BY personID ASC`);
	}, []);

	const initSizeAll = useCallback((skipHeader) => {
	}, []);

	const onGridReady = useCallback((params) => {
		setGridApi(params.api);
		initSizeAll(false);
		if (search && curLN) {
			getData(`SELECT * from ${curLN} where name like "%${search}%" ORDER BY personID ASC`);
		}

	});

	const defaultColDef = useMemo(() => {
		return {
			flex: 1,
			minWidth: 200,
			editable: false,
			resizable: true,
			wrapText: true,
			sortable: true,
			cellStyle: {
				textAlign: 'left',
			},
		};
	}, []);

	const onCellClicked = (e) => {
		//// displaying person info
		const to_tree_link = `../person?id=${e.data.personID}`;
		navigate(to_tree_link, {
			state: {
				data: e.data,
				modify: false,
				viewonly: true,
				dbdata: rawData,
			},
		});
	};

	const debouncedHandleChange = debounce((value) => {
		console.log('User has finished typing:', value);
		gridRef.current.api && gridRef.current.api.setGridOption("quickFilterText", search);
	}, 500);

	/// searching box
	const onFilterTextChange = (e) => {
		setSearch(e.target.value)
	}

	const onSearch = () => {
		debug(`current lastname: ${curLN} - search term : ${search}`)
		getData(`SELECT * from ${curLN} where name like "%${search}%" ORDER BY personID ASC`);
	}

	const clearSearchField = (e) => {
		setSearch("")
		getData(`SELECT * from ${curLN} ORDER BY personID ASC`);
	}

	const getData = async (querystr) => {
		const data = await dbQuery(querystr);
		setRawData(data);
	};
	const FetchLuong = () => {
		setSearch("")
		getData("Select * from LUONG ORDER BY personID ASC");
		setCurLN("LUONG")
	};
	const FetchMai = () => {
		setSearch("")
		getData("Select * from MAI ORDER BY personID ASC");
		setCurLN("MAI")
	};
	const FetchNgo = () => {
		setSearch("")
		getData("Select * from NGO ORDER BY personID ASC");
		setCurLN("NGO")
	};
	const FetchTrinh = () => {
		setSearch("")
		getData("Select * from TRINH ORDER BY personID ASC");
		setCurLN("TRINH")
	};
	const FetchBui = () => {
		setSearch("")
		getData("Select * from BUI ORDER BY personID ASC");
		setCurLN("BUI")
	};

	return (
		<Card className="table">
			<div>
				<h5 className="mt-1" style={{ color: "red" }}> All Individuals</h5>
				<div>
					<span className="mt-0 ml-0">
						<Button onClick={FetchNgo} text="Họ Ngô"></Button>
						<Button onClick={FetchLuong} text="Họ Lương"></Button>
						<Button onClick={FetchTrinh} text="Họ Trịnh"></Button>
						<Button onClick={FetchMai} text="Họ Mai"></Button>
						<Button onClick={FetchBui} text="Họ Bùi"></Button>
					</span>
				</div>
				<div className="mt-2">
					<span className="p-input-icon-left ">
						<IconField iconPosition="left">
							<InputText
								style={{ margin: "1px", height: "14px" }}
								className="p-inputtext-sm"
								onChange={onFilterTextChange}
								placeholder="Search Text ..."
								value={search}
							></InputText>
						</IconField>
					</span>
					<span className="p-input-icon-left pr-1 h-2">
						<ButtonIcon
							className="fa-solid fa-magnifying-glass ml-1 mr-2"
							type='submit'
							text="Search"
							onClick={onSearch}
							iconl={RiUserSearchFill}
						/>

						<ButtonIcon
							className="mr-2"
							type='submit'
							text="Clear"
							onClick={clearSearchField}
							iconl={GrClearOption}
						/>
					</span>
				</div>
				{rawData && rawData.length > 0 && (
					<div style={{ marginTop: "5px" }} className="ag-theme-alpine">
						<AgGridReact
							headerHeight={25}
							rowHeight={23}
							ref={gridRef}
							defaultColDef={defaultColDef}
							columnDefs={columnDefs}
							editType="fullRow"
							//suppressTouch
							suppressClickEdit={true}
							pagination={true}
							paginationPageSize={25}
							rowData={rawData}
							onGridReady={onGridReady}
							onCellClicked={onCellClicked}
							paginationPageSizeSelector={false}
						//alwaysShowHorizontalScroll
						></AgGridReact>
					</div>
				)}
			</div>
		</Card>
	);
};

export default People;
