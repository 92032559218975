import axios from "axios";
//import useToken from "../components/useToken";

export const dbQuery = async (queryKey) => {
  try {
    const URL = process.env.REACT_APP_SERVER_URL + "/query";
    const response = await axios
      .post(URL, {
        query: queryKey,
        "Access-Control-Allow-Origin": true,
      })
      .then((res) => {
        if (res && res.data) return res.data;
        else return [];
      });
    return response;
  } catch (error) {
    return error;
  }
};

export const dbQueryParams = async (queryKey, lastname, id, pid) => {
  try {
    const URL = process.env.REACT_APP_SERVER_URL + `/query/${id}`;
    const response = await axios
      .post(URL, {
        query: queryKey,
      })
      .then((res) => {
        if (res && res.data) return res.data;
        else return [];
      });
    return response;
  } catch (error) {
    return error;
  }
};

export const dbModify = async (data) => {
  try {
    const URL = process.env.REACT_APP_SERVER_URL + "/modify";
    const response = await axios
      .post(URL, {
        lastname: data.lastname,
        id: data.id,
        pid: data.pid,
        info: data.info,
        name: data.name,
        spouse: data?.spouse || '',
        spid: data?.spid || '',
        deceased: data?.deceased || '',
      })
      .then((res) => {
        if (res && res.data) return res.data;
        else return [];
      });
    return response;
  } catch (error) {
    return error;
  }
};

export const dbAdd = async (data) => {
  try {
    const URL = process.env.REACT_APP_SERVER_URL + "/insert";
    const response = await axios
      .post(URL, {
        lastname: data.lastname,
        id: data.id,
        pid: data.pid,
        info: data.info,
        name: data.name,
        spouse: data?.spouse || '',
        spid: data?.spid || '',
        deceased: data?.deceased || '',
      })
      .then((res) => {
        if (res && res.data) return res.data;
        else return [];
      });
    return response;
  } catch (error) {
    return error;
  }
};

export const dbDelete = async (data) => {
  try {
    const URL = process.env.REACT_APP_SERVER_URL + "/delete";
    const response = await axios
      .post(URL, {
        id: data.id,
      })
      .then((res) => {
        if (res && res.data) return res.data;
        else return [];
      });
    return response;
  } catch (error) {
    return error;
  }
};
